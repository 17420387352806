import { set, action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { next, scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import RSVP from 'rsvp';

import IntlService from 'ember-intl/services/intl';
import cloneDeep from 'lodash.clonedeep';

import ThankYouController from 'mobile-web/controllers/thank-you';
import { OnPremiseExperience, toPastOnPremiseOrder } from 'mobile-web/lib/on-premise';
import { getPaymentMethod } from 'mobile-web/lib/payment';
import { Variant } from 'mobile-web/lib/payment/method';
import { RecentOrder } from 'mobile-web/models/basket';
import OrderModel, { EcommerceOrderModel } from 'mobile-web/models/order';
import { StoredOrderSubmission } from 'mobile-web/models/order-submission';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import AppStoreReviewService from 'mobile-web/services/app-store-review';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';
import FeaturesService, { Metrics } from 'mobile-web/services/features';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import OnPremiseService, {
  generatedDineInEmailPrefix,
  generatedDineInEmailSuffix,
} from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';
import SplitCheckService from 'mobile-web/services/split-check';
import StorageService from 'mobile-web/services/storage';
import UserFeedbackService, { Type } from 'mobile-web/services/user-feedback';
import VendorService from 'mobile-web/services/vendor';
import WindowService from 'mobile-web/services/window';

export default class ThankYouRoute extends Route {
  @service analytics!: AnalyticsService;
  @service appStoreReview!: AppStoreReviewService;
  @service basket!: BasketService;
  @service channel!: ChannelService;
  @service globalEvents!: GlobalEventsService;
  @service intl!: IntlService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service splitCheck!: SplitCheckService;
  @service storage!: StorageService;
  @service store!: DS.Store;
  @service vendor!: VendorService;
  @service window!: WindowService;
  @service device!: DeviceService;
  @service features!: FeaturesService;
  @service userFeedback!: UserFeedbackService;

  @readOnly('session.isLoggedIn')
  isLoggedIn!: boolean;

  async setupController(
    controller: ThankYouController,
    model: ModelForRoute<this>,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);

    if (transition.to.queryParams.surveyCompleted) {
      this.analytics.trackEvent(AnalyticsEvents.SentimentSurveyCompleted);
      this.storage.sentimentSurveyShown = true;
    }

    const loyaltyProviderName = this.session.getLoyaltyTargetName(
      transition.to.queryParams.linkingTargetSlug
    );

    if (transition.to.queryParams.linkingError === 'true') {
      this.userFeedback.add({
        type: Type.Negative,
        title: '',
        message: this.intl.t('mwc.postCheckout.linkLoyaltyAccountFailure', {
          loyaltyProviderName,
        }),
      });

      this.analytics.trackEvent(
        AnalyticsEvents.LinkLoyaltyAccountError,
        () => ({
          [AnalyticsProperties.LinkLoyaltyTarget]: loyaltyProviderName,
        }),
        {
          bucket: 'all',
        }
      );
    } else if (transition.to.queryParams.linkingSuccessful === 'true') {
      this.userFeedback.add({
        type: Type.Positive,
        title: 'Success!',
        message: this.intl.t('mwc.postCheckout.linkLoyaltyAccountSuccess', {
          loyaltyProviderName,
        }),
      });

      this.analytics.trackEvent(
        AnalyticsEvents.LinkLoyaltyAccountCompleted,
        () => ({
          [AnalyticsProperties.LinkLoyaltyTarget]: loyaltyProviderName,
        }),
        {
          bucket: 'all',
        }
      );
    }

    next(this, () => {
      controller.set('surveyCompleted', undefined);
    });
  }

  beforeModel(transition: Transition) {
    if (transition.from?.localName === 'checkout') {
      this.basket.clear();
    }
  }

  model(params: { order_id: EmberDataId }): RSVP.Promise<OrderModel> {
    return this.store.findRecord('order', params.order_id).then(order => {
      this.vendor.set('vendor', order.vendor);
      return order;
    });
  }

  /**
   * @deprecated since 10/2020 in favor of global events
   */
  setEcomTrackingObj(this: ThankYouRoute, trackingObj: EcommerceOrderModel) {
    const OLO = this.window.OLO;
    set(OLO, 'ecomTrackingObj', [cloneDeep(trackingObj)]); // Clone this to prevent it being exposed globally and accidentally mutated
  }

  trackEcommerce(trackingObj: EcommerceOrderModel) {
    this.analytics.trackEcommerce(trackingObj);
  }

  trackTransaction(this: ThankYouRoute, order: OrderModel) {
    const ecomModel = order.serializeForEcommerce();
    const orderSubmission = this.storage.orderSubmission;
    this.setEcomTrackingObj(ecomModel);
    if (
      orderSubmission &&
      orderSubmission.basket &&
      this.session.lastOrderId !== this.storage.trackedThankYouOrderId
    ) {
      this.analytics.trackEcommerce(ecomModel);
      this.globalEvents.trigger(
        GlobalEventName.Transaction,
        order.serializeForGlobalData(),
        cloneDeep(orderSubmission),
        this.session.serializeUserForGlobalData()
      );
      this.trackMixpanel(order, orderSubmission);
      this.features.trackMetric(Metrics.OrderPlaced);
      this.storage.trackedThankYouOrderId = this.session.lastOrderId;
    }
  }

  trackMixpanel(model: ModelForRoute<this>, submission: StoredOrderSubmission) {
    if (this.onPremise.hasOpenCheck) {
      this.analytics.trackEvent(AnalyticsEvents.OpenCheckCheckClosed, () => ({
        [AnalyticsProperties.OpenCheckRoundsOrdered]: this.onPremise.openCheckRoundsOrdered ?? 0,
      }));
    }

    const properties = this.buildMixpanelProperties(model, submission);
    this.analytics.trackEvent(AnalyticsEvents.OrderPlaced, () => properties);
    this.analytics.trackEvent(
      AnalyticsEvents.OrderPlaced,
      () => ({
        ...properties,
        ...this.buildOloAuthMixpanelProperties(
          !!this.storage.signedInViaOloAuthOverlay,
          submission.createOloAccount
        ),
      }),
      { bucket: 'olo-auth' }
    );
    this.storage.signedInViaOloAuthOverlay = undefined;
  }

  buildMixpanelProperties(
    model: ModelForRoute<this>,
    { basket, memberships, selectedBillingMethods, loyaltyAccount }: StoredOrderSubmission
  ) {
    const properties: Dict<unknown> = {
      [AnalyticsProperties.OrderID]: model.id,
      [AnalyticsProperties.CheckId]: model.onPremiseDetails?.checkId,
      [AnalyticsProperties.SavedANewCard]:
        (this.session.savedNewCcCard || this.session.savedNewBrandedCard) ?? false,
      [AnalyticsProperties.EditedOrderFromCheckout]: this.session.editedOrderFromCheckout ?? false,
      [AnalyticsProperties.UsedASavedCard]: this.session.usedSavedCard ?? false,
      [AnalyticsProperties.ViewedCustomFeesTooltip]: this.session.viewedCustomFeesTooltip ?? false,
      [AnalyticsProperties.NumberOfPaymentTypesUsed]: selectedBillingMethods.length,
      [AnalyticsProperties.SupportsParkingLocation]: model.requireArrivalMessage,
      [AnalyticsProperties.SupportsArrivalNotifications]: model.canSupportArrival,
      [AnalyticsProperties.ToGoOrder]: this.isToGoOrder(model),
      //
    };

    if (this.isUtensilsOptIn(model)) {
      properties[AnalyticsProperties.IncludeUtensils] = this.isUtensilsOptIn(model);
    }

    if (this.onPremise.isEnabled) {
      properties[AnalyticsProperties.IsPlaceholderEmail] =
        (this.session.user?.emailAddress.startsWith(generatedDineInEmailPrefix) &&
          this.session.user?.emailAddress.endsWith(generatedDineInEmailSuffix)) ??
        false;
      properties[AnalyticsProperties.OrderOnPremiseTable] =
        model.onPremiseDetails?.tablePosReference;
      properties[AnalyticsProperties.OrderOnPremiseExperience] =
        model.onPremiseDetails?.experienceType;
    }

    properties[AnalyticsProperties.PaymentTypes] = selectedBillingMethods
      .map(method => {
        if (method.variant === Variant.OloPay) {
          if (method.paymentCard.isDigitalWallet) {
            /**
            Split pay is not supported for Google Pay or Apple Pay,
            so we know we are safe to use the first and only billingMethod here to determine the Digital Wallet type
          */
            const description = model?.billingComponents?.firstObject?.description
              .toString()
              .split(' ')
              .slice(0, 2) // Grab the first two words separated by a space (i.e. Google Pay, Apple Pay)
              .join(' ');

            return `Olo Pay Digital Wallet - ${description}`;
          }
          return 'Olo Pay Card';
        }

        return getPaymentMethod(method, memberships);
      })
      .sort();

    if (basket.coupon) {
      properties[AnalyticsProperties.CouponCode] = basket.coupon.code;
    }

    if (model.isSplit) {
      properties[AnalyticsProperties.SplitCheck] = true;
      Object.assign(
        properties,
        this.splitCheck.splitCheckAnalyticsProperties(model.onPremiseDetails!)
      );
      properties[AnalyticsProperties.SplitCheckHasSplitPayment] = true;
    } else {
      properties[AnalyticsProperties.SplitCheck] = false;
    }

    if (basket.reward && loyaltyAccount) {
      properties[AnalyticsProperties.LoyaltyRedemption] = true;
      properties[AnalyticsProperties.LoyaltyProvider] = loyaltyAccount.schemeProviderName;
      properties[AnalyticsProperties.LoyaltyMembershipID] = loyaltyAccount.membershipId;
      properties[AnalyticsProperties.LoyaltyRewardID] = basket.reward.externalReference;
      properties[AnalyticsProperties.LoyaltyRewardDiscountAmount] =
        basket.vendorDiscount.toFixed(2);
    } else {
      properties[AnalyticsProperties.LoyaltyRedemption] = false;
    }

    const nameOfModifiers: string[] = [];
    let totalModifiers = 0;

    const products = model.get('basketProducts').toArray();
    products.forEach(product => {
      const choices = product.get('basketChoices')?.toArray();

      choices.forEach(ch => {
        nameOfModifiers.push(ch.name);

        totalModifiers++;
      });
    });

    properties[AnalyticsProperties.NumberOfTotalMods] = totalModifiers;
    properties[AnalyticsProperties.BasketModifiers] = nameOfModifiers;

    return properties;
  }

  buildOloAuthMixpanelProperties(signedInViaOloAuthOverlay = false, createOloAccount = false) {
    return {
      [AnalyticsProperties.SignedInViaOloAuthOverlay]: signedInViaOloAuthOverlay,
      [AnalyticsProperties.CreateOloAuthAccount]: createOloAccount,
    };
  }

  afterModel(this: ThankYouRoute, model: ModelForRoute<ThankYouRoute>) {
    this.session.lastOrderId = model.id;
    scheduleOnce('afterRender', this, 'trackTransaction', model);

    this.storage.rwg_token = undefined;

    if (model.onPremiseDetails?.experienceType === OnPremiseExperience.PayAtTable) {
      this.onPremise.isPayAtTableOrder = true;
    }

    if (!this.isLoggedIn) {
      this.session.loadGuestUser();
    }

    const dispatchIds = model.hasMany('dispatchStatuses').ids();
    if (dispatchIds.length === 1) {
      this.router.replaceWith('dispatch-status', model.id, dispatchIds[0]);
    }

    if (this.onPremise.closedCheck) {
      const closedCheckData = this.onPremise.closedCheckData;
      // There will not be a user for newly created Borderless accounts, until allow-olo-auth-in-closed-check-olo-83762 is set to true
      const order = toPastOnPremiseOrder(model, this.session.currentUser);

      if (closedCheckData) {
        const pastOrders = closedCheckData.pastOrders;

        if (pastOrders.length === 0) {
          this.onPremise.closedCheckData = { ...closedCheckData, pastOrders: [order] };
        } else if (pastOrders && !pastOrders.find(o => o.displayId === order.displayId)) {
          this.onPremise.closedCheckData = {
            ...closedCheckData,
            pastOrders: [...pastOrders, order],
          };
        }
      }
    }

    if (
      this.device.isHybrid &&
      this.features.flags['app-rating-olo-37375'] &&
      this.channel.settings?.allowUserFeedback &&
      this.device.isCapacitorPluginAvailable('AppStoreReview')
    ) {
      const lastShownDate = this.storage.get('lastShownAppRatingPromptDate');
      const day = 24 * 60 * 60 * 1000;
      const minimumNumberOfDaysBetweenPrompts = 7;
      if (
        !lastShownDate ||
        Math.round(
          Math.abs((new Date().getTime() - new Date(Date.parse(lastShownDate)).getTime()) / day)
        ) >= minimumNumberOfDaysBetweenPrompts
      ) {
        this.appStoreReview.requestReview();
        this.storage.set('lastShownAppRatingPromptDate', new Date().toISOString());
      }
    }

    if (this.storage.orderSubmission) {
      const {
        orderId,
        datetime,
        isLoggedIn,
        basket: { basketProducts },
        firstName,
      } = this.storage.orderSubmission;
      const recentOrder: RecentOrder = {
        basketProducts,
        isLoggedIn,
        orderId,
        datetime,
        firstName,
      };
      this.addRecentOrderToVendorHistoryData(recentOrder);
      this.storage.orderItAgainClosed = false;
    }
  }

  @action
  willTransition() {
    // Clean up Mixpanel session properties
    this.session.savedNewBrandedCard = undefined;
    this.session.savedNewCcCard = undefined;
    this.session.editedOrderFromCheckout = undefined;
    this.session.usedSavedCard = undefined;
    this.session.viewedCustomFeesTooltip = undefined;
  }

  addRecentOrderToVendorHistoryData(order: RecentOrder) {
    this.storage.vendorHistory = this.storage.vendorHistory?.map(vendor =>
      vendor.slug === this.vendor.vendorSlug
        ? {
            ...vendor,
            recentOrder: order,
          }
        : vendor
    );
  }
  deactivate() {
    this.onPremise.isPayAtTableOrder = false;
    this.storage.orderSubmission = undefined;
  }

  isToGoOrder(model: OrderModel): boolean {
    if (model.customFieldValues) {
      const takeoutIndex = model.customFieldValues.findIndex(cf => cf.label === 'ToGoOrder');
      if (
        takeoutIndex > -1 &&
        model.customFieldValues[takeoutIndex].value ===
          this.intl.t('mwc.checkout.toGoCustomFieldValue')
      ) {
        return true;
      }
    }
    return false;
  }

  isUtensilsOptIn(model: OrderModel): string | undefined {
    return model.customFieldValues?.find(cf => cf.label === 'IncludeUtensils')?.value;
  }
}
