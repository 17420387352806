import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';

import { parseMenuImageURLDimensions } from 'mobile-web/lib/image';
import type CategoryModel from 'mobile-web/models/category';
import { GlobalProduct } from 'mobile-web/models/product';
import type ChannelService from 'mobile-web/services/channel';
import ErrorService from 'mobile-web/services/error';
import { ProductClickFrom } from 'mobile-web/services/global-data';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import type StorageService from 'mobile-web/services/storage';

import style from './index.m.scss';

const PRODUCT_VISIBLE_THRESHOLD = 0.5;

interface Args {
  // Required arguments
  category: CategoryModel;

  // Optional arguments
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class MenuCategory extends Component<Signature> {
  // Service injections
  @service channel!: ChannelService;
  @service globalEvents!: GlobalEventsService;
  @service storage!: StorageService;
  @service error!: ErrorService;

  // Untracked properties
  style = style;
  productIntersectionObserver = new IntersectionObserver(this.trackVisibleProducts, {
    threshold: [PRODUCT_VISIBLE_THRESHOLD],
  });
  viewedProducts = new Set();

  // Tracked properties

  // Getters and setters
  /**
   * All of the products in the category.
   */
  get products() {
    return this.args.category.products;
  }

  /**
   * All of the products to be shown in the category.
   */
  get shownProducts() {
    return this.args.category.isRecentItem
      ? this.products.filter(product => product.isVisible).sortBy('recentItemSortOrder')
      : this.args.category.isMostOrdered
      ? this.products.filter(product => product.isVisible).sortBy('mostOrderedRank')
      : this.products.filter(product => product.isVisible).sortBy('isDisabled');
  }

  /**
   * The total number of products to show in the category.
   */
  get productCount() {
    return this.shownProducts.length;
  }

  get showCategoryName(): boolean {
    return (
      isEmpty(this.args.category.images) || this.channel.settings?.showMenuCategoryNames || false
    );
  }

  get bannerImage() {
    const src = this.args.category.bannerImage;

    if (!src) {
      return undefined;
    }

    const { width, height } = parseMenuImageURLDimensions(src, this.error);
    return {
      src,
      alt: this.args.category.name,
      width,
      height,
    };
  }

  get clickFrom(): ProductClickFrom {
    return this.args.category.isRecentItem
      ? ProductClickFrom.RecentItem
      : this.args.category.isMostOrdered
      ? ProductClickFrom.MostOrdered
      : ProductClickFrom.VendorMenu;
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions and helpers
  /**
   * Tracks products that are visible on screen.
   *
   * TODO: migrate this to tracking service
   *
   * @param entries
   * @returns
   */
  @action
  trackVisibleProducts(entries: IntersectionObserverEntry[]) {
    const products = entries
      .filter(entry => entry.intersectionRatio > PRODUCT_VISIBLE_THRESHOLD)
      .map(entry => entry.target as HTMLElement)
      .map(target => +(target.dataset.index ?? ''))
      .map(index => this.shownProducts.objectAt(index)?.serializeForGlobalData())
      .filter<GlobalProduct>(
        (product): product is GlobalProduct =>
          (product && !this.viewedProducts.has(product.id)) ?? false
      );

    if (!products.length) {
      return;
    }

    products.forEach(product => this.viewedProducts.add(product.id));
    this.globalEvents.trigger(GlobalEventName.ProductsVisible, products);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MenuCategory: typeof MenuCategory;
  }
}
